import React from "react";
import { Container, Typography } from "@material-ui/core";
import withAuth from "../auth/withAuth";
import AuthHelperMethods from "../../util/AuthHelperMethods";
import ExportPopover from "../ExportPopover";
import ExportPopoverPreventieves from "../ExportPopoverPreventieves";

const Auth: any = new AuthHelperMethods();

const Home = function Home() {
  return (
    <Container component="main" maxWidth="xs">
      <img
        src="lifecare.png"
        style={{ width: "100%", marginBottom: "15px" }}
        alt="Life-care"
      ></img>
      <div style={{ textAlign: "center", marginBottom: "15px" }}>
        <Typography component="h1" variant="h4">
          Welkom,{" "}
          {Auth.getConfirm().firstName
            ? Auth.getConfirm().firstName
            : Auth.getConfirm().userName}
          !
        </Typography>
      </div>
      <div style={{ padding: "15px" }}>
        <ExportPopover />
      </div>
      <div style={{ padding: "15px" }}>
        <ExportPopoverPreventieves />
      </div>
    </Container>
  );
};

export default withAuth(Home);
