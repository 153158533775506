import { Registration } from "../../../common";
import AuthHelperMethods from "../util/AuthHelperMethods";
import { Base } from "../../../common";

const Auth = new AuthHelperMethods();

export const getRegistrations = (userId: string): Promise<Registration[]> => {
  return Auth.fetch("/api/registrations?userId=" + userId, {})
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
};

export const getAllRegistrationsWithinRange = (
  from: Date,
  to: Date
): Promise<Registration[]> => {
  return Auth.fetch(
    "/api/registrations?from=" + from.toISOString() + "&to=" + to.toISOString(),
    {}
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
};

export const getAllRegistrationsWithProperties = (
  from: Date,
  to: Date,
  user: string,
  base: Base
): Promise<Registration[]> => {
  let fetchUrl: string = "/api/registrations?";
  if (from && to) {
    fetchUrl =
      fetchUrl + "&from=" + from.toISOString() + "&to=" + to.toISOString();
  }
  if (user) {
    fetchUrl = fetchUrl + "&userId=" + user;
  }
  if (base) {
    fetchUrl = fetchUrl + "&base=" + base;
  }
  return Auth.fetch(fetchUrl, {})
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
};

export const getRegistrationsWithinRange = (
  userId: string,
  from: Date,
  to: Date
): Promise<Registration[]> => {
  return Auth.fetch(
    "/api/registrations?userId=" +
      userId +
      "&from=" +
      from.toISOString() +
      "&to=" +
      to.toISOString(),
    {}
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
};

export const newRegistration = (
  start: Date,
  end: Date,
  base: Base,
  nbOfCalls: number,
  amount: number
) => {
  const body: object = {
    start: start.toISOString(),
    end: end.toISOString(),
    base,
    nbOfCalls,
    amount: amount.toFixed(2),
  };
  return Auth.fetch("/api/registrations", {
    method: "POST",
    body: JSON.stringify(body),
  });
};

export const patchRegistration = (
  id: string,
  start: Date,
  end: Date,
  base: Base,
  nbOfCalls: number,
  amount: number
) => {
  const body: object = {
    start: start.toISOString(),
    end: end.toISOString(),
    base,
    nbOfCalls,
    amount: amount.toFixed(2),
  };
  return Auth.fetch("/api/registrations/" + id, {
    method: "PATCH",
    body: JSON.stringify(body),
  });
};

export const deleteRegistration = (id: string) => {
  return Auth.fetch("/api/registrations/" + id, {
    method: "DELETE",
  });
};
