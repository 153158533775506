export interface User {
  _id: string;
  username: string;
  password?: string;
  level: UserLevel;
  firstName: string;
  lastName: string;
  email: string;
  iban: string;
}

export interface Registration {
  _id: number;
  user: User;
  start: Date;
  end: Date;
  base: Base;
  nbOfCalls: number;
  amount: string;
}

export enum UserLevel {
  ADMIN = "ADMIN",
  USER = "USER",
}

export enum Base {
  Gent = "GENT",
  Preventieve = "PREVENTIEVE",
}

export function baseFormatter(base: Base): string {
  switch (base) {
    case Base.Gent:
      return "Gent";
    case Base.Preventieve:
      return "Preventieve";
    default:
      return "PROBLEEM";
  }
}
