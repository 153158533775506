import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Container,
  Typography,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Button,
  Chip,
} from "@material-ui/core";
import { DateTimePicker } from "@material-ui/pickers";
import { Registration, baseFormatter, Base } from "../../../../common";
import withAuth from "../auth/withAuth";
import AuthHelperMethods from "../../util/AuthHelperMethods";
import { getRegistrationsWithinRange } from "../../services/registrationService";
import RegistrationScreen from "./Registration";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { withStyles } from "@material-ui/core/styles";
// import CloudDownload from "@material-ui/icons/CloudDownload";
// @ts-ignore
// import ReactExport from "react-export-excel";

const Registrations = function Registrations() {
  const Auth: any = new AuthHelperMethods();

  const [registrations, setRegistrations]: [any, any] = useState(null);
  const [activeRegistration, setActiveRegistration]: [
    Registration | null,
    any
  ] = useState(null);
  const [filterFrom, setFilterFrom] = useState(
    moment().startOf("month").toDate()
  );
  const [filterTo, setFilterTo] = useState(moment().endOf("month").toDate());

  useEffect(() => {
    setRegs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterFrom, filterTo]);

  const setRegs = async () => {
    setRegistrations(null);
    const data = await getRegistrationsWithinRange(
      Auth.getConfirm()._id,
      filterFrom,
      filterTo
    );
    setRegistrations(data);
  };

  const onChangeFilterFrom = (value: any) => {
    setFilterFrom(value);
  };

  const onChangeFilterTo = (value: any) => {
    setFilterTo(value);
  };

  const calcTotalAmount = (registrations: Registration[]): number => {
    let total = 0;
    registrations.forEach((row) => (total = total + parseFloat(row.amount)));
    return total;
  };

  const back = () => {
    setRegistrations(null);
    setRegs();
    setActiveRegistration(null);
  };

  const GentChip = withStyles({
    root: {
      backgroundColor: "#03A9F4",
      color: "white",
    },
  })(Chip);

  const PreventieveChip = withStyles({
    root: {
      backgroundColor: "#e74c3c",
      color: "white",
    },
  })(Chip);

  // const ExcelFile = ReactExport.ExcelFile;
  // const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  // const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  if (registrations) {
    if (activeRegistration) {
      return (
        <RegistrationScreen registration={activeRegistration} back={back} />
      );
    } else {
      if (registrations.length > 0) {
        return (
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Container component="main" maxWidth="lg">
              <div style={{ textAlign: "center", marginBottom: "30px" }}>
                <Typography component="h1" variant="h4">
                  Registraties
                </Typography>
              </div>
              <ExpansionPanel style={{ marginBottom: "15px" }}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Filter</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div className="filterContainer">
                    <div className="filter">
                      <div className="filterField">
                        <DateTimePicker
                          ampm={false}
                          label="Van"
                          inputVariant="outlined"
                          value={filterFrom}
                          onChange={onChangeFilterFrom}
                          style={{ width: "100%" }}
                        />
                      </div>
                      <div className="filterField">
                        <DateTimePicker
                          ampm={false}
                          label="Tot"
                          inputVariant="outlined"
                          value={filterTo}
                          onChange={onChangeFilterTo}
                          style={{ width: "100%" }}
                        />
                      </div>
                    </div>
                    <div className="filter">
                      <div className="filterField">
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          fullWidth={true}
                          onClick={() => {
                            setFilterFrom(
                              moment()
                                .subtract(1, "months")
                                .startOf("month")
                                .toDate()
                            );
                            setFilterTo(
                              moment()
                                .subtract(1, "months")
                                .endOf("month")
                                .toDate()
                            );
                          }}
                        >
                          Vorige Maand
                        </Button>
                      </div>
                      <div className="filterField">
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          fullWidth={true}
                          onClick={() => {
                            setFilterFrom(moment().startOf("month").toDate());
                            setFilterTo(moment().endOf("month").toDate());
                          }}
                        >
                          Deze maand
                        </Button>
                      </div>
                    </div>
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              {/* <ExpansionPanel style={{ marginBottom: "15px" }}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>Exporteren</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div className="filter">
                    <ExcelFile
                      element={
                        <Button
                          startIcon={<CloudDownload />}
                          variant="contained"
                          color="primary"
                          size="large"
                          fullWidth={true}
                        >
                          Download excel export
                        </Button>
                      }
                    >
                      <ExcelSheet data={registrations} name="Registrations">
                        <ExcelColumn
                          label="Dag"
                          value={(row: any) =>
                            moment(row.start).format("DD/MM/YYYY")
                          }
                        />
                        <ExcelColumn
                          label="Van"
                          value={(row: any) =>
                            moment(row.start).format("HH:mm")
                          }
                        />
                        <ExcelColumn
                          label="Tot"
                          value={(row: any) =>
                            moment(row.start).format("HH:mm")
                          }
                        />
                        <ExcelColumn
                          label="Standplaats"
                          value={(row: any) => row.base}
                        />
                        <ExcelColumn
                          label="Aantal ritten"
                          value={(row: any) => row.nbOfCalls}
                        />
                        <ExcelColumn
                          label="Verloning"
                          value={(row: any) => "€" + row.amount}
                        />
                      </ExcelSheet>
                    </ExcelFile>
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel> */}
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Dag</TableCell>
                      <TableCell>Van</TableCell>
                      <TableCell>Tot</TableCell>
                      <TableCell>Standplaats</TableCell>
                      <TableCell>Aantal ritten</TableCell>
                      <TableCell>Verloning</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(registrations as Registration[]).map((row) => (
                      <TableRow
                        hover
                        style={{ cursor: "pointer" }}
                        key={row._id}
                        onClick={() => setActiveRegistration(row)}
                      >
                        <TableCell>
                          {moment(row.start).format("DD/MM/YYYY")}
                        </TableCell>
                        <TableCell>
                          {moment(row.start).format("HH:mm")}
                        </TableCell>
                        <TableCell>{moment(row.end).format("HH:mm")}</TableCell>
                        <TableCell>
                          {row.base === Base.Gent ? (
                            <GentChip
                              size="small"
                              label={baseFormatter(row.base)}
                            />
                          ) : (
                            <PreventieveChip
                              size="small"
                              label={baseFormatter(row.base)}
                            />
                          )}
                        </TableCell>
                        <TableCell>
                          {row.base === Base.Preventieve
                            ? "n.v.t."
                            : row.nbOfCalls}
                        </TableCell>
                        <TableCell>&euro; {row.amount}</TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell align="right">
                        <strong>Totaal: </strong>
                      </TableCell>
                      <TableCell>
                        &euro; {calcTotalAmount(registrations).toFixed(2)}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Container>
          </MuiPickersUtilsProvider>
        );
      } else {
        return (
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Container component="main" maxWidth="lg">
              <div style={{ textAlign: "center", marginBottom: "30px" }}>
                <Typography component="h1" variant="h4">
                  Registraties
                </Typography>
              </div>
              <ExpansionPanel>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Filter</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div className="filterContainer">
                    <div className="filter">
                      <div className="filterField">
                        <DateTimePicker
                          ampm={false}
                          label="Van"
                          inputVariant="outlined"
                          value={filterFrom}
                          onChange={onChangeFilterFrom}
                          style={{ width: "100%" }}
                        />
                      </div>
                      <div className="filterField">
                        <DateTimePicker
                          ampm={false}
                          label="Tot"
                          inputVariant="outlined"
                          value={filterTo}
                          onChange={onChangeFilterTo}
                          style={{ width: "100%" }}
                        />
                      </div>
                    </div>
                    <div className="filter">
                      <div className="filterField">
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          fullWidth={true}
                          onClick={() => {
                            setFilterFrom(
                              moment()
                                .subtract(1, "months")
                                .startOf("month")
                                .toDate()
                            );
                            setFilterTo(
                              moment()
                                .subtract(1, "months")
                                .endOf("month")
                                .toDate()
                            );
                          }}
                        >
                          Vorige Maand
                        </Button>
                      </div>
                      <div className="filterField">
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          fullWidth={true}
                          onClick={() => {
                            setFilterFrom(moment().startOf("month").toDate());
                            setFilterTo(moment().endOf("month").toDate());
                          }}
                        >
                          Deze maand
                        </Button>
                      </div>
                    </div>
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <div style={{ textAlign: "center", marginTop: "30px" }}>
                <Typography component="h1" variant="h6">
                  Je hebt geen registraties in deze periode!
                </Typography>
              </div>
            </Container>
          </MuiPickersUtilsProvider>
        );
      }
    }
  } else {
    return (
      <div style={{ textAlign: "center", marginTop: "20%" }}>
        <CircularProgress color="inherit" />
      </div>
    );
  }
};

export default withAuth(Registrations);
