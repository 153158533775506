import React, { useState, useRef, useEffect } from "react";
import withAuth from "../auth/withAuth";
import { makeStyles } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DateTimePicker } from "@material-ui/pickers";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Snackbar,
  Container,
  Typography,
} from "@material-ui/core";
import { Base } from "../../../../common";
import EasyTapButton from "../EasyTapButton";
import NumberBox from "../NumberBox";
import SaveIcon from "@material-ui/icons/Save";
import { newRegistration } from "../../services/registrationService";
import CompensationCalculationHelper from "../../../../common/CompensationCalculationHelper";
// pick a date util library
import MomentUtils from "@date-io/moment";
import moment from "moment";
import "moment/locale/nl";
moment.locale("nl");

const useStyles = makeStyles((theme) => ({
  marginElement: {
    margin: "30px",
  },
}));

const NewRegistration = function NewRegistration() {
  const classes = useStyles();
  const inputLabel: any = useRef(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [base, setBase] = useState(Base.Gent);
  const [labelWidth, setLabelWidth] = useState(0);
  const [nbOfCalls, setNbOfCalls] = useState(0);
  const [amount, setAmount] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const compensationCalculationHelper = new CompensationCalculationHelper();

  useEffect(() => {
    setStartDate(moment(startDate).set({ second: 0, millisecond: 0 }).toDate());
    setEndDate(moment(endDate).set({ second: 0, millisecond: 0 }).toDate());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const calc = () => {
      console.log("calc called");
      if (moment(startDate).isBefore(endDate)) {
        console.log("Setting amount");
        const totalAmount = compensationCalculationHelper.calculateTotalAmount(
          startDate,
          endDate,
          nbOfCalls,
          base
        );
        console.log(totalAmount);
        setAmount(totalAmount.totalAmount);
      } else {
        console.log("setting 0 amount");
        setAmount(0);
      }
    };
    calc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, base, nbOfCalls]);

  const handleStartDateChange = (value: any) => {
    setStartDate(value.set({ second: 0, millisecond: 0 }));
  };

  const handleEndDateChange = (value: any) => {
    setEndDate(value.set({ second: 0, millisecond: 0 }));
  };

  const handleBaseChange = (event: any) => {
    setBase(event.target.value);
  };

  const decrementNbOfCalls = (event: any) => {
    if (nbOfCalls > 0) {
      setNbOfCalls(nbOfCalls - 1);
    }
  };

  const incrementNbOfCalls = (event: any) => {
    setNbOfCalls(nbOfCalls + 1);
  };

  const submitRegistration = async () => {
    if (moment(startDate).isBefore(endDate)) {
      await newRegistration(startDate, endDate, base, nbOfCalls, amount);
      setNbOfCalls(0);
      setStartDate(new Date());
      setEndDate(new Date());
      setSnackbarOpen(true);
    } else {
      alert("End date is before start date?");
    }
  };

  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  return (
    <Container component="main" maxWidth="lg">
      <div style={{ textAlign: "center", marginBottom: "30px" }}>
        <Typography component="h1" variant="h4">
          Nieuwe tijdsregistratie
        </Typography>
      </div>
      <div className={classes.marginElement}>
        <MuiPickersUtilsProvider
          utils={MomentUtils}
          libInstance={moment}
          locale="nl"
        >
          <DateTimePicker
            ampm={false}
            label="Start van de shift"
            inputVariant="outlined"
            value={startDate}
            onChange={handleStartDateChange}
            format="DD/MM/YYYY HH:mm"
            style={{ width: "100%" }}
          />
        </MuiPickersUtilsProvider>
      </div>
      <div className={classes.marginElement}>
        <MuiPickersUtilsProvider
          utils={MomentUtils}
          libInstance={moment}
          locale="nl"
        >
          <DateTimePicker
            ampm={false}
            label="Einde van de shift"
            inputVariant="outlined"
            value={endDate}
            onChange={handleEndDateChange}
            format="DD/MM/YYYY HH:mm"
            style={{ width: "100%" }}
          />
        </MuiPickersUtilsProvider>
      </div>
      <div className={classes.marginElement}>
        <FormControl variant="outlined" style={{ width: "100%" }}>
          <InputLabel ref={inputLabel} id="base">
            Standplaats
          </InputLabel>
          <Select
            labelId="base"
            id="base-selector"
            value={base}
            labelWidth={labelWidth}
            onChange={handleBaseChange}
          >
            <MenuItem value={Base.Gent}>Gent</MenuItem>
            <MenuItem value={Base.Preventieve}>Preventieve</MenuItem>
          </Select>
        </FormControl>
      </div>
      {base !== Base.Preventieve &&
        moment(endDate).date() === moment(startDate).date() && (
          <div className={classes.marginElement}>
            <div>
              <p>
                Aantal ritten (Transporten naar ziekenhuis <strong>OF</strong>{" "}
                Verzorgingen ter plaatse met parameters in ambureg)
              </p>
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <EasyTapButton label="-" onClick={decrementNbOfCalls} />
                <div>
                  <NumberBox label={nbOfCalls} />
                </div>
                <EasyTapButton label="+" onClick={incrementNbOfCalls} />
              </div>
            </div>
          </div>
        )}
      {base !== Base.Preventieve &&
        moment(endDate).date() !== moment(startDate).date() && (
          <div className={classes.marginElement}>
            <div>
              <h4>Deze shift loopt over meerdere dagen.</h4>
              <p>
                Er zal 1 registratie aangemaakt worden per dag. Vergeet niet het
                aantal ritten achteraf in te geven voor een correcte berekening!
              </p>
            </div>
          </div>
        )}
      <div className={classes.marginElement}>
        <div style={{ width: "100%", textAlign: "center" }}>
          <p>
            Berekende vergoeding: <strong>&euro; {amount.toFixed(2)}</strong>
          </p>
        </div>
      </div>
      <div className={classes.marginElement} style={{ marginTop: "20px" }}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          startIcon={<SaveIcon />}
          fullWidth={true}
          onClick={submitRegistration}
        >
          Opslaan
        </Button>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        autoHideDuration={6000}
        message="Tijdsregistratie opgeslagen!"
      />
    </Container>
  );
};

export default withAuth(NewRegistration);
