import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import CloseIcon from "@material-ui/icons/Close";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import DescriptionIcon from "@material-ui/icons/Description";
import moment from "moment";
import {
  getdocx,
  getPreventievesDocx,
} from "../services/documentGenerationService";
import { getUsers } from "../services/userService";
import { User, UserLevel } from "../../../common";
import "moment/locale/nl";
moment.locale("nl");

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    marginElement: {
      margin: "30px",
    },
  })
);

export default function ExportPopoverContentAdmin(props: any) {
  const classes = useStyles();
  const [users, setUsers]: [User[], any] = React.useState([]);
  const [user, setUser]: [string, any] = React.useState("");
  const [month, setMonth] = React.useState(moment().format("MMMM"));
  const [year, setYear] = React.useState(moment().format("YYYY"));

  const handleMonthChange = (event: any) => {
    setMonth(event.target.value);
  };

  const handleYearChange = (event: any) => {
    setYear(event.target.value);
  };

  const handleUserChange = (event: any) => {
    setUser(event.target.value);
  };

  const years = () => {
    const years = [];
    const dateStart = moment().set({ year: 2019 });
    const dateEnd = moment();
    while (dateEnd.diff(dateStart, "years") >= 0) {
      years.push(dateStart.format("YYYY"));
      dateStart.add(1, "year");
    }
    return years;
  };

  const fetchUsers = async () => {
    const users = await getUsers();
    setUsers(
      users
        .filter((u) => u.level === UserLevel.USER)
        .sort((u1, u2) =>
          u1.firstName < u2.firstName ? -1 : u2.firstName < u1.firstName ? 1 : 0
        )
    );
  };

  React.useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <Dialog fullScreen open={props.open} onClose={props.handleClose}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={props.handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Genereer onkostennota
          </Typography>
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="lg" style={{ padding: "30px" }}>
        <div className={classes.marginElement}>
          Selecteer hieronder de gebruiker, de maand en het jaar waarvan je de
          onkostennota wilt genereren. Klik daarna op de knop "Genereer
          onkostennota (docx)"
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div className={classes.marginElement} style={{ width: "50%" }}>
            <FormControl variant="filled" style={{ width: "100%" }}>
              <InputLabel id="user">Gebruiker</InputLabel>
              <Select
                labelId="user"
                id="user-selector"
                value={user}
                onChange={handleUserChange}
                disabled={!users}
              >
                {users.map((u: User, index) => {
                  return (
                    <MenuItem key={index} value={u._id}>
                      {u.firstName + " " + u.lastName + " (" + u.username + ")"}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
          <div className={classes.marginElement} style={{ width: "50%" }}>
            <FormControl variant="filled" style={{ width: "100%" }}>
              <InputLabel id="month">Maand</InputLabel>
              <Select
                labelId="month"
                id="month-selector"
                value={month}
                onChange={handleMonthChange}
              >
                {moment.months().map((m, index) => {
                  return (
                    <MenuItem key={index} value={m}>
                      {m}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
          <div className={classes.marginElement} style={{ width: "50%" }}>
            <FormControl variant="filled" style={{ width: "100%" }}>
              <InputLabel id="month">Jaar</InputLabel>
              <Select
                labelId="year"
                id="year-selector"
                value={year}
                onChange={handleYearChange}
              >
                {years().map((y, index) => {
                  return (
                    <MenuItem key={index} value={y}>
                      {y}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        </div>
        <div className={classes.marginElement}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            startIcon={<DescriptionIcon />}
            fullWidth={true}
            onClick={() =>
              props.onlyPreventieves
                ? getPreventievesDocx(user, month, year)
                : getdocx(user, month, year)
            }
          >
            Genereer onkostennota (docx)
          </Button>
        </div>
      </Container>
    </Dialog>
  );
}
